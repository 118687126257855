import { defineStore } from 'pinia';
import { useAuthStore } from '@/plugins';
import { useLoadingStore } from './loading';
import api from '@/utils/api';
import Cookies from 'js-cookie';
import Cookie from 'js-cookie';
import emitter from '@/utils/event-bus';
import account from '@/pages/agent/account';

export interface Profile {
  account: Account;
  permissions: string[];
  role: string;
}

export interface Account {
  username: string;
  avatar: string;
  gender: number;
}

export type TokenResult = {
  token: string;
  expires: number;
};

export const useAccountStore = defineStore('account', {
  state() {
    return {
      account: {} as Account,
      permissions: [] as string[],
      role: '',
      currency: '',
      logged: true,
    };
  },
  actions: {
    async login(username: string, password: string) {
      return api.post('/auth/login', { username, password })
        .then(async (response) => {
          this.logged = true;
          Cookies.set('accessToken', response.data.token, { expires: response.data.expires });
          return response.data;
        });
    },
    async logout() {
      return new Promise<boolean>((resolve) => {
        localStorage.removeItem('stepin-menu');
        Cookies.set('accessToken', null);
        Cookies.set('token2FA', null);
        this.logged = false;
        resolve(true);
      });
    },
    async googleAuth(username: string, totp: string) {
      return api.post('/auth/google-auth/verify', { username, totp })
        .then(async (response) => {
          console.log('2fa success');
          Cookie.set('token2FA', response.data.token);
          emitter.emit('googleAuthSuccess', totp);
          return response.data;
        });
    },
    async profile() {
      const { setAuthLoading } = useLoadingStore();
      setAuthLoading(true);
      return api.get('/auth/info')
        .then(({ data }) => {
          console.log(data);
          const { setAuthorities } = useAuthStore();
          const { account, permissions, role, currency } = data;
          this.account = account;
          this.permissions = permissions;
          this.role = role;
          this.currency = currency;

          setAuthorities(permissions);
          return data;
        })
        .finally(() => setAuthLoading(false));
    },
  },
});
