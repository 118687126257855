<template>
  <a-modal :visible="visible" @cancel="closeProfile">
    <a-form :label-col="{span: 4}">
      <a-form-item name="username" label="用户名">
        {{ account.account.username }}
      </a-form-item>
      <a-form-item name="avatar" label="头像">
        <div class="w-16 h-16">
          <a-image :src="account.account.avatar" />
        </div>
      </a-form-item>
      <a-form-item name="role" label="角色">
        {{ account.role }}
      </a-form-item>
      <a-form-item name="action" label="操作">
        <a-button type="primary" @click="updatePassword">
          修改密码
        </a-button>
      </a-form-item>
    </a-form>
    <template #footer></template>
  </a-modal>

  <a-modal :visible="showUpdateModal" @cancel="cancelUpdate" @ok="submitUpdatePassword">
    <a-form :model="updateForm" :rules="rules" :label-col="{span: 4}" v-model="updateForm">
      <a-form-item name="rawPassword" label="原密码" required>
        <a-input v-model:value="updateForm.rawPassword" />
      </a-form-item>
      <a-form-item name="newPassword" label="新密码" required>
        <a-input-password v-model:value="updateForm.newPassword" autocomplete="newPassword" />
      </a-form-item>
      <a-form-item name="checkPassword" label="确认密码" required>
        <a-input-password v-model:value="updateForm.checkPassword" autocomplete="checkPassword" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup>

import { useAccountStore } from "@/store/account";
import { reactive, ref } from "vue";
import api from "@/utils/api";
import { message } from "ant-design-vue";


const visible = ref(false)
const account = useAccountStore()
const showUpdateModal = ref(false)
const updateForm = reactive({ rawPassword: '', newPassword: '', checkPassword: '' })

const rules = {
  rawPassword: {
    pattern: /^[a-zA-Z0-9]{4,12}/,
    trigger: 'blur',
    message: '密码为4位字母或者数字'
  },
  newPassword: {
    pattern: /^[a-zA-Z0-9]{4,12}/,
    trigger: 'blur',
    message: '密码为4位字母或者数字'
  },
  checkPassword: [
    {
      pattern: /^[a-zA-Z0-9]{4,12}/,
      trigger: 'blur',
      message: '密码为4位字母或者数字'
    },
    {
      message: '两次密码必须一致',
      trigger: 'blur',
      validator: (_rule, value) => {
        console.log(value)
        if (value !== updateForm.newPassword) {
          return Promise.reject('两次密码不一致')
        }
        return Promise.resolve()
      }
    }
  ]
}

const open = () => {
  visible.value = true
}

const cancelUpdate = () => showUpdateModal.value = false

const updatePassword = () => {
  showUpdateModal.value = true
}

const closeProfile = () => {
  visible.value = false
}

const submitUpdatePassword = () => {
  console.log(updateForm)
  api.put('/user/update-password', updateForm)
    .then(() => {
      message.success("密码修改成功");
      showUpdateModal.value = false
    })
}

defineExpose({ open })
</script>

<style scoped>

</style>
