import axios from 'axios';
import Cookie from 'js-cookie';
import router from '@/router/index';
import emitter from '@/utils/event-bus';
import { notification } from 'ant-design-vue';

const api = axios.create({
  baseURL: import.meta.env.VITE_BASE_API_URL,
  timeout: 10000,
});


api.interceptors.request.use(config => {
  config.headers['x-hipay-app'] = import.meta.env.VITE_APP_NAME;
  let accessToken = Cookie.get('accessToken');
  if (accessToken) {
    config.headers['Authorization'] = 'Bearer ' + accessToken;
  }
  let token2FA = Cookie.get('token2FA');
  if (token2FA) {
    config.headers['x-hipay-2fa'] = token2FA;
  }
  return config;
});

api.interceptors.response.use(
  res => {
    return res;
  },
  e => {
    const { response } = e;
    const { error, description } = response.data;
    console.log('api error,error:' + error + ', message:' + description);
    if (error === 'Auth') {
      if (description === 'username password mismatch') {
        notification.error({ message: '用户名或密码错误' });
        return Promise.reject(e);
      }
      Cookie.set('accessToken', null);
      return router.push('/login');
    } else if (error === 'Auth_2FA') {
      console.log('show 2fa');
      emitter.emit('show2FA', { cb: () => console.log('verify 2fa error ') });
      return Promise.reject(e);
    } else {
      notification.error({ message: description });
    }
    return Promise.reject(e);
  });

export default api;
