import { createRouter, createWebHashHistory, RouterOptions } from 'vue-router';
import routes from './routes';
import guards from './guards';
import { reactive } from 'vue';

const router = createRouter(reactive({
  history: createWebHashHistory(),
  routes,
}) as RouterOptions);

// 注册导航守卫
guards.before.forEach(router.beforeEach);
guards.after.forEach(router.afterEach);

export default router;
