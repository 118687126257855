<template>
  <ThemeProvider :color="{ middle: { 'bg-base': '#fff' }, primary: { DEFAULT: '#1896ff' } }">
    <div class="rounded-sm">
      <a-form
        :model="form"
        :wrapperCol="{ span: 24 }"
        @finish="login"
        class="login-form w-[400px] p-lg xl:w-[440px] xl:p-xl h-fit text-text"
      >
        <a-form-item :required="true" name="username">
          <a-input
            v-model:value="form.username"
            autocomplete="new-username"
            placeholder="请输入用户名或邮箱: admin"
            class="login-input h-[40px]"
          />
        </a-form-item>
        <a-form-item :required="true" name="password">
          <a-input
            v-model:value="form.password"
            autocomplete="new-password"
            placeholder="请输入登录密码: 888888"
            class="login-input h-[40px]"
            type="password"
          />
        </a-form-item>

        <a-button htmlType="submit" class="h-[40px] w-full" type="primary" :loading="loading">
          登录
        </a-button>
      </a-form>
    </div>
  </ThemeProvider>
</template>
<script lang="ts" setup>
  import { reactive, ref } from 'vue';
  import { useAccountStore } from '@/store';
  import { ThemeProvider } from 'stepin';
  import emitter from '@/utils/event-bus';

  export interface LoginFormProps {
    username: string;
    password: string;
  }

  const loading = ref(false);

  const form = reactive<LoginFormProps>({
    username: '',
    password: '',
  });

  const emit = defineEmits<{
    (e: 'success'): void;
    (e: 'failure', reason: string): void;
    (e: 'bind'): void;
  }>();

  const accountStore = useAccountStore();


  function login() {
    loading.value = true;
    console.log('try login');
    accountStore.account.username = form.username;

    accountStore
      .login(form.username, form.password)
      .then((data) => {
        if (data.needBindGoogleAuth) {
          emit('bind');
        } else {
          emit('success');
        }
      })
      .catch((reason) => {
        console.log('login fail');
        emit('failure', reason);
      })
      .finally(() => (loading.value = false));
  }

  emitter.on('googleAuthSuccess', () => login());
</script>
