import {storeToRefs} from 'pinia';
import {NavigationGuard, NavigationHookAfter} from 'vue-router';
import {useLoadingStore} from '@/store';
import {useAuthStore} from '@/plugins';
import NProgress from 'nprogress';
import {clearPage} from 'stepin/es/tabs-view';
import 'nprogress/nprogress.css';
import Cookie from "js-cookie";

NProgress.configure({showSpinner: false});

interface NaviGuard {
  before?: NavigationGuard;
  after?: NavigationHookAfter;
}

const loginGuard: NavigationGuard = function (to, from) {
  let accessToken = Cookie.get('accessToken');
  if (!accessToken && !/^\/(login|dashboard)?$/.test(to.fullPath)) {
    return '/login';
  }
};
// 进度条
const ProgressGuard: NaviGuard = {
  before(to, from) {
    NProgress.start();
  },
  after(to, from) {
    NProgress.done();
  },
};


// 403 forbidden
const ForbiddenGuard: NaviGuard = {
  before(to) {
    const {hasAuthority} = useAuthStore();
    if (to.meta?.permission && !hasAuthority(to.meta?.permission)) {
      const {authLoading} = storeToRefs(useLoadingStore());
      return {
        path: '/403',
        query: {
          permission: to.meta.permission,
          path: to.fullPath,
          loading: authLoading.value as any
        },
      };
    } else if (to.name === '403' && (to.query.permission || to.query.path)) {
      clearPage(to.query.path as string);
      const {permission, path, loading} = to.query;
      to.params.permission = permission;
      to.params.path = path;
      to.params.loading = loading;
    }
  },
};

// 404 not found
const NotFoundGuard: NaviGuard = {
  before(to, from) {
    const {pageLoading} = useLoadingStore();
    if (to.meta._is404Page && pageLoading) {
      to.params.loading = true as any;
    }
  },
};

export default {
  before: [ProgressGuard.before, ForbiddenGuard.before, loginGuard, NotFoundGuard.before],
  after: [ProgressGuard.after],
};
